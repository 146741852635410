












import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api"

import { useI18n } from "@/_i18n"

import {
  createDefaultUserData,
  GetUserRequest,
  loadUser,
  User,
  UserData,
} from "@/_service/user"

import { useApiCall } from "@/_use/apiCall"

import Loader from "@/component/Loader.vue"
import UserCreateUpdate from "@/view/User/component/UserCreateUpdate.vue"

export default defineComponent({
  name: "UserUpdate",

  components: { Loader, UserCreateUpdate },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n()

    const userName = computed(() => props.id)
    const getUserQuery = reactive<GetUserRequest>({ userName: props.id })
    const userData = reactive<UserData>(createDefaultUserData())

    const { onSubmit, isLoading, errorMessage } = useApiCall<
      GetUserRequest,
      User
    >(loadUser, t, getUserQuery, (data) => {
      userData.userName = data?.userName as string
      userData.clientId = data?.clientId
      userData.displayName = data?.displayName
      userData.emailAddress = data?.emailAddress
      userData.permissions = data?.permissions
    })

    watch(
      userName,
      async () => {
        getUserQuery.userName = userName.value
        onSubmit()
      },
      { immediate: true }
    )

    return {
      errorMessage,
      isLoading,
      userData,
    }
  },
})
